.App {
    text-align: center;
}

.logo {
    height: 8vmin;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.wide-container {
    margin: 20px;
}

.nav-item {
    margin-left: 20px;
    margin-right: 30px;
    margin-bottom: 10px
}
.nav-link {
    color: #555555;
    margin-bottom:0;
    padding-bottom:0;
}
.nav-link:hover {
    color: black;
    margin-bottom: 0;
    border-bottom: 1px solid #555555;
}

li.nav-item > a.active {
    color: black;
    font-weight: bold;
    margin-bottom: 0;
}

/*About*/
.aboutListItem {
    border: 1px solid lightgrey;
}
.linkCol {
    text-align: center;
}
.infoLink {
    font-size: 15px;
    color: #555555;
    margin-right: 10px;
    margin-left: 10px;
}
.infoLink:hover {
    color: #555555;
}

.termsStyle {
    font-family: "Times New Roman, Arial, Helvetica", serif;
}


/*Gallery*/
.galleryCarousel {
    margin: auto;
    width:70vw;
    height:75vh;
}

.galleryImage {
    max-width: 70vw;
    max-height: 70vh;
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.galleryCaption {
    color: black;
    font-weight: bold;
    background-color: lightgrey;
    opacity: 0.75;
    position: relative;
    bottom: -5vh;
    z-index: 15;

}

.carousel-indicators li {
    background-color: #ccc;
    border: 2px solid #666;
}

/*Your Timeline*/
button.button-link {
    padding: 0 4px;
    background: none;
    border: none;
    text-decoration: underline;
}

.timeline__save-button {
    margin-bottom: 5px;
}

.info__logged-in {
    display: none;
}

.share__not-logged-in {
    display: none;
}

.share__logged-in {
    align-items: baseline;
    display: flex;
}

.opt-in-checkbox {
    margin-right: 8px;
}

div .personBorder {
    border-right: 1px solid gray;
    border-left: 1px solid gray;
    border-bottom: 1px solid gray;
    padding: 10px;
}

.personName {
    font-weight: 500;
    line-height: 1.2;
    font-size: 1rem;
    margin-bottom: 10px;
}

.eye {
    float: right;
    margin-top: 0.2em;
    color: grey;
}

.eye:hover {
    color: black;
}

.collapseChevron{
    float: right;
    margin-top: 0.2em;
    margin-left: 0.5em;
}

.collapseHeader:hover span{
    font-weight: bold;
    color: #888888;
    cursor: pointer;
}

.collapseHeader:hover {
    cursor: pointer;
}

.js-plotly-plot .plotly .modebar {
    left: 0;
    transform: translateX(5%);
}

.button-container {
    display: block;
}

/*Modal*/
.modal__help-text {
    padding: 1rem 1rem 0;
}

.modal-body {
    display: flex;
}

.modal-body input {
    flex-grow: 1;
}

.modal__message {
    font-size: 14px;
    padding: 1rem 1rem 0;
}

.modal__action-complete-message {
    background-color: #05ff01;
    font-weight: 700;
    padding: 1rem;
}

.modal__action-failed-message {
    background-color: #dc3545;
    font-weight: 700;
    padding: 1rem;
}

.form-group .react-datepicker-popper {
    z-index: 2;
}

.save-button-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 10px;
}

.saved-message {
    transition: opacity 300ms;
}

.saved-message--visible {
    opacity: 1;
}

.saved-message--hidden {
    opacity: 0;
}

